import { StaticImage } from 'gatsby-plugin-image';
import React, { useState,useEffect } from 'react'


const Addon =()=> {

  const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
      const updateMedia = () => {
        setIsDesktop(window.innerWidth > 768);
      };

      window.addEventListener("resize", updateMedia);
      updateMedia(); // Check initial size

      return () => window.removeEventListener("resize", updateMedia);
    }, []);

  // Tab
  const openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Add on Modules</span> in our </span>
              OpenSea Clone Script</h3>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={openTabSection} className="current" onClick={(e) => openTabSection(e, 'tabs1')}>
                  Payment Gateway
                </li>
                <li role='presentation' onKeyDown={openTabSection} onClick={(e) => openTabSection(e, 'tabs2')} >
                  Escrow Based Trade
                </li>
                <li role='presentation' onKeyDown={openTabSection} onClick={(e) => openTabSection(e, 'tabs4')} >
                  API Documentation
                </li>

              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Payment Gateway
                      </h4>
                      <p className="pharagraph">Using our software, users can purchase NFTs using digital wallets and assets and buy using any fiat currency. We provide the facility to integrate multiple payment options including credit/debit cards, bank wire, and payment gateways.
                      </p>
                      
                    </div>
                    {isDesktop && (
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/payment-gateway.png"
                      alt="Payment Gateway"
                      width={380}
                    />
                    </div>
                    )}
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Escrow Based Trade
                      </h4>
                      <p className="pharagraph">The digital collectibles in the platform are well protected by the escrow system which is especially integrated to prevent fraudulent activities. It is more useful for the creators to neglect the risk of transferring their digital assets before the payment is made.
                      </p>
                     
                    </div>
                    {isDesktop && (
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea/escrow-based-trade.png"
                      alt="Escrow Based Trade"
                      width={350}
                    />
                    </div>
                    )}
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">API Documentation</h4>
                      <p className="pharagraph">Our solution can provide a rest API for fetching NFT items based on a set of parameters that includes sales, bids, transfers, and more. Using this API, you can connect your OpenSea like NFT Marketplace with other platforms and pull relevant data.
                      </p>
                     
                    </div>
                    {isDesktop && (
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/api-documentation.png"
                      alt="API Documentation"
                      width={400}
                    />
                    </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Addon;