import { StaticImage } from 'gatsby-plugin-image';
import React,{useEffect,useState} from 'react'

const WhyShould =()=> {

  const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
      const updateMedia = () => {
        setIsDesktop(window.innerWidth > 768);
      };

      window.addEventListener("resize", updateMedia);
      updateMedia(); // Check initial size

      return () => window.removeEventListener("resize", updateMedia);
    }, []);


    return (
      <section className="whyshould pt-100 mb-0">
        <div className="container">
              <h2 className="heading-h2 text-center"><span className="heading-h3 bluecolor">Why should you</span> Create NFT Marketplace using Our OpenSea Clone Script?
              </h2>
              <p className='text-center marbot-0'>
              Building an NFT Marketplace using our OpenSea clone script helps to reduce significant development costs. Here are the benefits of the software.
              </p>
          <div className="row table-content">
            {isDesktop && (
            <div className="col-lg-6 col-md-12 text-center" >
              <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/why-should-you-create-nft-marketplace-using-opensea-clone-script.png"
                      alt="Create an NFT Marketplace using our OpenSea Clone Script"
                      width={625}
                    />
            </div>
            )}
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph">Built with multi-layered security features to ensure safety.
                </li>
                <li className="pharagraph">Fastest way to build an NFT Marketplace like Opensea and deploy on a blockchain. 
                </li>
                <li className="pharagraph">More affordable in comparison to development from scratch.
                </li>
                <li className="pharagraph">Can be altered according to your business needs
                </li>
                <li className="pharagraph">Comes with highly influential UI/UX features.
                </li>
                <li className="pharagraph">Our OpenSea Clone is compatible with multiple blockchains.</li>
                <li className="pharagraph">Seamless Wallet integration.
                </li>
                <li className="pharagraph">Increased ROI along with brand identity.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    )
  }


export default WhyShould;