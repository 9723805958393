import * as React from "react"

import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Banner from "../components/Opensea/Banner"
import Whatis from "../components/Opensea/Whatis"
//import QuickviewSection from "../components/Opensea/Quickview"
import HowOur from "../components/Opensea/HowOur"
import TopFeatures from "../components/Opensea/TopFeatures"
import CoreFeatures from "../components/Opensea/CoreFeatures"
import SecurityofOur from "../components/Opensea/SecurityofOur"
import TechStack from "../components/Opensea/TechStack"
import FaqSection from "../components/Opensea/FaqSection"
import WhyShould from "../components/Opensea/WhyShould"
// import DevApproach from "../components/Opensea/DevApproach"
import WhyChoose from "../components/Opensea/WhyChoose"
import Addon from "../components/Opensea/Addon"
import OurAndroid from "../components/Opensea/OurAndroid"
//import Testimonial from "../components/Opensea/Testimonial"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"
import VariousBlock from "../components/Opensea/VariousBlock"
import DevMethod from "../components/Opensea/RevenueGen"
import Categories from "../components/Opensea/Categories"
import WhiteLables from "../components/Opensea/WhiteLable"





const Clone = () => (
  <section className="landing clone opensea">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>OpenSea Clone Script | Create NFT Marketplace like OpenSea</title>
        <meta name="description" content="OpenSea clone script is a customizable software used to create a robust ROI-based P2P NFT Marketplace like Opensea with advanced security and trading features." />
        <meta property="keywords" content="OpenSea Clone,OpenSea Clone Script,OpenSea Clone software" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="OpenSea Clone Script | Create NFT Marketplace like OpenSea" />
        <meta property="og:description" content="OpenSea clone script is a customizable software used to create a robust ROI-based P2P NFT Marketplace like Opensea with advanced security and trading features." />
        <meta property="og:url" content="https://www.coinsclone.com/opensea-clone-script/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/opensea-clone-script.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="OpenSea clone script is a customizable software used to create a robust ROI-based P2P NFT Marketplace like Opensea with advanced security and trading features." />
        <meta name="twitter:title" content="OpenSea Clone Script | Create NFT Marketplace like OpenSea" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/opensea-clone-script.png" />
        <link rel="canonical" href="https://www.coinsclone.com/opensea-clone-script/" />
      </Helmet>
      <Banner />
      <div className="breadcrumb marbot-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | OpenSea Clone Script </span>
        </div>
      </div>
      <Whatis />
      <WhyShould />
      {/* <QuickviewSection /> */}
      <HowOur />
      <TopFeatures />
      <VariousBlock />
      <CoreFeatures />
      <SecurityofOur />
      <Categories />
      <Addon />
      <DevMethod />
      <OurAndroid /> 
      {/* <Testimonial /> */}
      <WhyChoose />
      {/* <DevApproach /> */}
      <TechStack />
      <WhiteLables />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default Clone
