import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">
      <Container>

      <div className="text-center">
          
          <h5 className="heading-h2"><span className="heading-h3 bluecolor">FAQ </span> 
            Frequently Asked Questions</h5>
        </div>

        <Accordion defaultActiveKey={activeId}>
        <div >
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                Why is OpenSea Clone preferred for NFT Marketplace development?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">OpenSea Clone allows faster entry to the market and easier brand building compared to other NFT Marketplace development methods. This is why a majority of startups are looking for a top-notch script that replicates the functionalities of Opensea. 
                </div>
              </Accordion.Collapse>
            </div>
          </div>
          <div >
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                Does your OpenSea Clone support multiple blockchains?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Yes! Our OpenSea clone script is compatible with different blockchain networks like Ethereum, Tron, Binance Smart Chain, etc. Multiple blockchain compatibility feature is a major advantage of choosing our software solution. 
                </div>
              </Accordion.Collapse>
            </div>
          </div>
          
          <div >
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                What is the actual cost of an OpenSea clone?
                </Accordion.Toggle>
              </div> 
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">The estimated cost of an OpenSea clone software ranges between $8,000 to $15,000. This price can differ according to the requirements and customizations needed in the software.
                </div>
              </Accordion.Collapse>
            </div>
          </div>
          <div >
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                How long does it take to launch an OpenSea-like NFT platform?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">With our White label OpenSea clone, you can launch an NFT Marketplace like OpenSea in just seven to ten days. However, for additional features to be included, the development time can slightly increase respectively.
                </div>
              </Accordion.Collapse>
            </div>
          </div>
          <div >
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                How will the OpenSea clone script help me to generate revenue?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Our Opensea clone software has numerous revenue-generating ways including listing fees, minting fees, trading fees, registration, and auction bidding fees. Your revenue-making strategies can also be implemented since our software allows customizations.
                </div>
              </Accordion.Collapse>
            </div>
          </div>
        </Accordion>
      </Container>
    </section>
  )
}

export default FaqSection