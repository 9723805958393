import React,{useState,useEffect} from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image'

const OurAndroid =()=> {

  const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
      const updateMedia = () => {
        setIsDesktop(window.innerWidth > 768);
      };

      window.addEventListener("resize", updateMedia);
      updateMedia(); // Check initial size

      return () => window.removeEventListener("resize", updateMedia);
    }, []);


    return (
      <section className="android pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-left leftpad">
            
            <h4 className="heading-h2"><span className="heading-h3">Coinsclone’s <span className="bluecolor">OpenSea Clone App</span> For </span>
            Android and iOS</h4>
          </div>

          <div className="row table-content orderflex mobilewhitebox">
            <div className="col-md-12 col-lg-7 order2 martb-0">
              <p className="pharagraph marbot-0">We offer best-in-class OpenSea Clone app development along with clone services. As of now, many traders prefer mobile-based NFT trading platforms for buying and selling digital assets. By using our finest OpenSea clone app development service, you can launch a stunning NFT trading mobile app like OpenSea which is compatible with both Android and IOS devices. The OpenSea clone app also works on the reputed Ethereum blockchain network and the framework of the app is designed using the latest technology stacks. By launching a superfine NFT Marketplace app with the help of Coinsclone, you can attract many global traders and creators in a short time.
              </p>
              <ButtonComponent />
            </div>
            {isDesktop && (
            <div className="col-md-12 col-lg-5 and-img text-center order1">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/opensea-new/opensea-clone-app-development.png"
                  alt="OpenSea clone app development"
                  width={525} />
            </div>
            )}
          </div>

          <div className="inner-container">
            <div className="platform">
              <div className="t-black plat-dev">Platforms Developed</div>
              <div className="playstoreicon">
                <div className="play">
                  <img className='lazyload' src="https://coinsclone.mo.cloudinary.net/images/android.svg" alt="Android" />
                  <span>Android</span> </div>
                <div className="play">
                  <img className='lazyload' src="https://coinsclone.mo.cloudinary.net/images/apple.svg" alt="Apple" />
                  <span>ios</span> </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

export default OurAndroid