import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis mb-0 py-0">
        <div className='gray-bg'>
        <div className="container">
          <div className="shadoww">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <h2 className="heading-h2"><span className="bluecolor">What is</span> OpenSea Clone Script?</h2>
              </div>
            </div>
            <div className="text-center">
              <p className="pharagraph">OpenSea Clone Script is a ready-to-deploy software solution that helps to start your own NFT Marketplace resembling the popular OpenSea platform. The software is 100% multi-tested, built with powerful cutting-edge technologies, and is specially made for entrepreneurs who prioritize security. Our OpenSea Clone enables you to create an NFT Marketplace where users can trade, sell, and buy digital collectibles with the best decentralized experience on diverse blockchain networks.
              </p>
              <p className="pharagraph mb-0">Coinsclone’s White Label OpenSea Clone script has all the necessary features to develop a peer-to-peer NFT Marketplace Platform. From storefront, NFT minting, and wallets, our software covers a wide range of features to attract a large user base to your platform. Overall, our OpenSea clone replicates all the core functionalities and plug-ins of OpenSea and is a great option to start your NFT Marketplace business.</p>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis